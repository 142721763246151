  <template>
  <v-row>
    <v-col>
      <picker
        :rules="required"
        :label="$t('t.Type')"
        class="mt-5"
        :disabled="!collaborationTaskIsNew"
        :clearable="true"
        :selected-id.sync="collaborationTaskTypeId"
        document-types="collaboration-task-types"
      />

    </v-col>
    <v-col>
      <date-picker-menu
        v-model="targetDate"
        :disabled="!collaborationTaskIsNew && !collaborationTask.canUpdate"
        :label="$t('t.TargetDate')"
        :rules="required"
        :min="moment().add(1,'days').format('YYYY-MM-DD')"
      />
    </v-col>
  </v-row>
</template>

<script>
import DocumentEditor from '@/mixins/document-editor'

export default {
  mixins: [DocumentEditor],
  components: {
    DatePickerMenu: () => import('@/components/date-picker-menu'),
    Picker: () => import('@/components/picker')
  },
  data () {
    return {
      required: [
        v => !!v || this.$t('t.IsRequired')
      ]
    }
  },
  computed: {
    cacheType () {
      return DocumentEditor.CacheType.CollaborationTaskTypeRef
    },
    id () {
      return this.collaborationTask.collaborationTaskTypeId
    },
    collaborationTaskTypeId: {
      get: function () {
        return this.collaborationTask.collaborationTaskTypeId
      },
      set: function (collaborationTaskTypeId) {
        this.$emit('update:collaboration-task', Object.assign({}, this.collaborationTask, { collaborationTaskTypeId }))
        this.$nextTick(() => {
          if (collaborationTaskTypeId) {
            this.$waitFor(() => this.document).then(() => {
              this.$emit('update:type')
            })
          }
        })
      }
    },
    targetDate: {
      get () {
        return this.collaborationTask.targetDate
      },
      set (targetDate) {
        this.$emit('update:collaboration-task', Object.assign({}, this.collaborationTask, { targetDate }))
      }
    }
  },
  props: {
    collaborationTask: Object,
    statusWillCloseCollaboration: Boolean,
    collaborationTaskIsNew: Boolean
  }
}
</script>

<style lang="stylus" scoped></style>
